import React from 'react';
import styled from 'styled-components';
import { DataTable, type DataTablePassThroughOptions, DataTableRowEditCompleteEvent, } from 'primereact/datatable';
import { BespokeResource, type Complaint, } from '@/stub';
import { Column, ColumnBodyOptions, ColumnEditorOptions } from "primereact/column";
import { useToastMessagesStore } from "@/Stores/ToastMessagesStore";
import { useQueryClient } from "@tanstack/react-query";
import { useGetBespokes } from "@/Service/Api/ApiHooks/ComplaintBespokes/useGetBespokes";
import { snakeToNormalCase } from "@/helpers/general";
import BaseTextEditor from "@/components/Core/Form/BaseTextEditor";
import { useUpdateBespoke } from "@/Service/Api/ApiHooks/ComplaintBespokes/useUpdateBespoke";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { BespokeEditedMessage } from "@/Messages/Toast/Bespokes/BespokeEditedMessage";
import { Button } from "primereact/button";
import { useDeleteBespoke } from "@/Service/Api/ApiHooks/ComplaintBespokes/useDeleteBespoke";
import { BespokeDeletedMessage } from "@/Messages/Toast/Bespokes/BespokeDeletedMessage";
import { formatToUKDate } from "@/Util/formatToUKDate";

type TableColumnDefinition = {
    label?: string
    sortable?: boolean
    field?: string
    sortField?: string
    body?: React.ReactNode | ((data: BespokeResource, options: ColumnBodyOptions) => React.ReactNode)
    editor?: (options: ColumnEditorOptions) => React.ReactNode
    rowEditor?: boolean
    headerStyle?: React.CSSProperties
    colSpan?: number
};

const dataTablePtOptions: DataTablePassThroughOptions = {
    root: {
        className: 'datatable-base'
    }
};

const StyledWrap = styled.main`
    .datatable-base {
        font-size: 0.8rem;
        font-weight: 500;
    }
`;

export type BespokesDatatableProps = {
    complaint: Complaint
};

/**
 * Files datatable
 */
const BespokesDatatable: React.FC<BespokesDatatableProps> = ({ complaint }: BespokesDatatableProps) => {
    const queryClient = useQueryClient();
    const updateBespokeMutation = useUpdateBespoke();
    const deleteBespokeMutation = useDeleteBespoke();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const textEditor = (options: ColumnEditorOptions) => {
        return <BaseTextEditor
            value={options.value}
            onEditorChange={(e) => options.editorCallback!(e)}
            onKeyDown={(e) => e.stopPropagation()}
        />;
    };

    const onRowEditComplete = async (e: DataTableRowEditCompleteEvent) => {
        await updateBespokeMutation.mutateAsync({
            bespoke_id: e.newData.id,
            complaint_id: complaint.id,
            UpsertBespokeRequest: {
                bespoke: e.newData.bespoke,
                letter_type: e.data.letter_type
            }
        }, {
            onSuccess: () => {
                addToastMessage(BespokeEditedMessage);
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.bespokes.list({ complaint_id: complaint?.id }).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.letters.complaint({ complaint_id: complaint?.id }).queryKey
                    })
                ]);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const onBespokeDelete = async (bespoke: BespokeResource) => {
        await deleteBespokeMutation.mutateAsync({
            bespoke_id: bespoke.id,
            complaint_id: complaint.id
        }, {
            onSuccess: () => {
                addToastMessage(BespokeDeletedMessage);
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.bespokes.list({ complaint_id: complaint?.id }).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.letters.complaint({ complaint_id: complaint?.id }).queryKey
                    })
                ]);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    const tableColumns: TableColumnDefinition[] = [
        {
            label: 'ID',
            field: 'id'
        },
        {
            label: 'Bespoke',
            field: 'bespoke',
            body: data => <span dangerouslySetInnerHTML={{ __html: data.bespoke }}/>,
            editor: textEditor
        },
        {
            label: 'Letter Type',
            field: 'letter_type',
            body: data => <span>{snakeToNormalCase(data.letter_type)}</span>
        },
        {
            label: 'Created',
            field: 'created_at',
            body: data => <span>{formatToUKDate(data.created_at, true)}</span>
        },
        {
            label: 'Actions',
            rowEditor: true,
            headerStyle: { width: '2%' },
            colSpan: 2
        },
        {
            body: data => <Button
                label='Delete'
                onClick={() => onBespokeDelete(data)}
                loading={updateBespokeMutation.isPending || deleteBespokeMutation.isPending}
            />
        },
    ];

    const {
        data: bespokes,
        isLoading: loading
    } = useGetBespokes({
        complaint_id: complaint.id
    });

    return (
        <StyledWrap>
            <div className="content-container">
                <div className="datatable-container">
                    <DataTable
                        lazy
                        loading={loading}
                        emptyMessage="No bespokes found."
                        value={bespokes}
                        editMode='row'
                        pt={dataTablePtOptions}
                        onRowEditComplete={onRowEditComplete}
                    >
                        {tableColumns.map((column, index) =>
                            <Column
                                key={`bespokes-column-${index}`}
                                field={column.field}
                                body={column.body}
                                header={column.label}
                                editor={column.editor}
                                rowEditor={column.rowEditor}
                                headerStyle={column.headerStyle}
                                colSpan={column.colSpan}
                            />
                        )}
                    </DataTable>
                </div>
            </div>
        </StyledWrap>
    );
};

export default BespokesDatatable;
